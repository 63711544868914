import { computed, ref, readonly } from '@vue/composition-api';

const state = ref<{
  paused: null | boolean
}>({
  paused: null,
});

const marqueePaused = computed(() => state.value.paused);

let pauseMarqueeTimeout: number;
const setMarqueePaused = (paused: boolean) => {
  if (process.client) {
    clearTimeout(pauseMarqueeTimeout);

    pauseMarqueeTimeout = window.setTimeout(() => {
      state.value.paused = paused;
    }, 150);
  }
};

export const marqueeState = readonly({
  marqueePaused,
  setMarqueePaused,
});
