



















import { computed, defineComponent } from '@vue/composition-api';
import { IMarqueeAttrs } from './types';
import { marqueeState } from './marquee.state';
import { componentsWhiteList } from './helpers';

export default defineComponent({
  name: 'Marquee',
  inheritAttrs: false,
  components: {
    TrainingThumbnail: () => import('~/components/storyblokDynamic/TrainingThumbnail/TrainingThumbnail.vue'),
  },
  setup(_, context) {
    const {
      slides,
      direction,
      scrollSpeed,
    } = context.attrs as IMarqueeAttrs;

    return {
      handlePause(val: boolean) {
        marqueeState.setMarqueePaused(val);
      },
      contentStyles: computed(() => ({
        animationDirection: direction ?? 'linear',
        animationDuration: `${(scrollSpeed ?? 20)}s`,
        animationPlayState: marqueeState.marqueePaused.value ? 'paused' : '',
      })),
      slides: computed(() => slides?.filter(({ component }) => componentsWhiteList.has(component))),
    };
  },
});
